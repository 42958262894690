<template>
  <section class="py-15">
    <v-container class="container-custom">
      <v-row>
        <v-col md="12">
          <div class="text-center" v-if="$route.params.orderNumber != 'noorderplaced'">
            <v-icon class="successIcon"> mdi-check </v-icon>
            <h1>Order successfully submitted.</h1>
            <p>Order Number : {{ $route.params.orderNumber }}</p>
            <p class="mb-8">
              Please be sure to check the product before accepting the shipment
            </p>
            <router-link to="/"
              ><v-btn class="mb-10 mr-5">
                Continue Shopping
              </v-btn></router-link
            >
            <router-link to="/orders"
              ><v-btn class="mb-10"> Your Orders </v-btn></router-link
            >
          </div>
          <div class="text-center" v-if="$route.params.orderNumber == 'noorderplaced'">
            <v-icon class="errorIcon"> mdi-close </v-icon>
            <h1>Unsuccessful Payment</h1>
            <p>Order was not placed due to payment failure</p>
            <p class="mb-8">Please choose another payment method!</p>
            <router-link to="/"
              ><v-btn class="mb-10 mr-5">
                Continue Shopping
              </v-btn></router-link
            >
            <router-link to="/checkout"
              ><v-btn class="mb-10"> Your Checkout </v-btn></router-link
            >
          </div>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  name: "OrderConfirm",
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
.v-btn {
  min-height: 50px;
  width: 300px;
}
h1 {
  font-size: 30px;
  margin-bottom: 10px;
}
.v-btn {
  color: #fff;
}
.v-icon {
  border: 5px solid #000;
  font-size: 100px;
  border-radius: 50%;
  margin: 30px 0;
}
.successIcon {
  color: green !important;
  border-color: green;
}
.errorIcon {
  color: red !important;
  border-color: red;
}
</style>